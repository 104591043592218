<style lang="less" scoped>
  .other_arrangements {
    padding: 40px;
  }
  .budget_cont {
    text-align: center;
    span {
      color: #f00;
      font-weight: bold;
    }
  }
  .pagefooter {
    padding: 30px 0;
    text-align: center;
  }
  .Attachment_information {
    width: 100%;
    height: 104px;
    background-color: #eee;
    text-align: center;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > span {
      padding-right: 15px;
    }
  }
  .no_arrangement {
    background-color: #f8f8f8;
    text-align: center;
    color: #666;
  }
</style>
<template>
  <a-form
    :label-col="formLayout.labelCol"
    :wrapper-col="formLayout.wrapperCol"
    v-bind="rformProps.modelRef"
    @submit="onSubmit"
  >
    <div class="pageTitle">
      <a-divider orientation="left">
        基本信息
      </a-divider>
    </div>
    <a-form-item label="前往地点：">
      {{ projectInfo.out_place }}
    </a-form-item>
    <a-form-item label="出发日期：">
      {{ projectInfo.start_time }}
    </a-form-item>
    <a-form-item label="回程日期：">
      {{ projectInfo.back_time }}
    </a-form-item>
    <a-form-item label="出差项目：" v-if="projectInfo.project">
      {{ projectInfo.project.name }}
    </a-form-item>
    <a-form-item label="出差事由：">
      {{ projectInfo.out_reason }}
    </a-form-item>
    <div class="pageTitle">
      <a-divider orientation="left">
        出差人员
      </a-divider>
    </div>
    <MyPersonnel :show="projectInfo.out_people_json" />
    <div class="pageTitle">
      <a-divider orientation="left">
        交通工具
      </a-divider>
    </div>
    <MyTraffic :show="projectInfo.out_vehicle_json" />
    <div class="pageTitle">
      <a-divider orientation="left">
        入住酒店
      </a-divider>
    </div>
    <MyHotel :show="projectInfo.out_hotel_json" />
    <div class="pageTitle">
      <a-divider orientation="left">
        其他安排
      </a-divider>
    </div>
    <div class="other_arrangements no_arrangement">
      {{ projectInfo.other_plan ? projectInfo.other_plan : '无其他安排' }}
    </div>
    <div class="pageTitle">
      <a-divider orientation="left">
        费用预算
      </a-divider>
    </div>
    <a-row class="other_arrangements budget_cont">
      <a-col :span="12">
        预支差旅费：
        <span>¥{{ projectInfo.advance }}</span>
      </a-col>
      <a-col :span="12">
        旅费预算：
        <span>¥{{ projectInfo.budget }}</span>
      </a-col>
    </a-row>
    <div class="pageTitle">
      <a-divider orientation="left">
        附件信息(邀请函)
      </a-divider>
    </div>
    <my-attachments-json :datajson="projectInfo.attachment_json" />
    <div class="pageTitle">
      <a-divider orientation="left">
        审批人员
      </a-divider>
    </div>
    <my-approve :approvedata="projectInfo" />
    <div class="pagefooter">
      <a-button style="margin-left: 10px;" @click="goPage()">
        返回
      </a-button>
    </div>
  </a-form>
</template>
<script>
  import { useForm } from '@ant-design-vue/use'
  import { reactive, ref, computed, watchEffect, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter, useRoute } from 'vue-router'
  import { notification } from 'ant-design-vue'
  import { API, requests } from '@/api/apis'
  import MyPersonnel from '../components/show_personnel.vue'
  import MyTraffic from '../components/show_traffic_tool.vue'
  import MyHotel from '../components/showhotel.vue'
  import MyApprove from '../components/Approve.vue'
  import MyAttachmentsJson from '../components/MyAttachmentsJson.vue'
  export default {
    inject: ['formLayout'],
    emits: ['finish'],
    components: {
      MyAttachmentsJson,
      MyPersonnel,
      MyTraffic,
      MyHotel,
      MyApprove,
    },
    setup(props, ctx) {
      const projectInfo = ref({})
      const route = useRoute()
      const attachments_json = ref([])
      const store = useStore()
      const router = useRouter()
      const user = ref(computed(() => store.state.auth.user))
      const modelRef = reactive({
        //表单
        projects_id: undefined, //创建时间
        out_place: undefined, //修改金额
        start_time: undefined, //修改次数
        back_time: undefined, //名称
        out_reason: undefined, //编号
        out_people_json: undefined, //出差人员
        out_vehicle_json: undefined, //交通工具
        out_hotel_json: undefined, //入住酒店
        other_plan: undefined, //其他的安排
        advance: undefined, //预支差旅费
        budget: undefined, //旅费预算
        attachment_json: undefined, //附件信息
        workflow_json: undefined, //工作流审批
      })
      const workflow_json = ref([])
      const validateInfoFun = () => {
        let status = false
        workflow_json.value.forEach((res) => {
          if (status) return false
          if (!res.admins_id) {
            notification['warning']({
              message: '提示',
              description: `请选择审批人员！【${res.label_name}】`,
            })
            status = true
          }
        })
        return status
      }
      watchEffect(() => {
        workflow_json.value = [
          {
            label_name: '发起人',
            admins_id: user.value.id,
            role_name: user.value.realname,
          },
          { label_name: '总监', admins_id: undefined, role_name: undefined },
          {
            label_name: '董事',
            admins_id: undefined,
            role_name: undefined,
          },
          {
            label_name: '财务总监',
            admins_id: undefined,
            role_name: undefined,
          },
          {
            label_name: '行政部',
            admins_id: undefined,
            role_name: undefined,
          },
        ]
      })
      const rulesRef = reactive({
        //校验
        projects_id: [
          {
            required: true,
            type: 'number',
            message: '请选择项目！',
            trigger: 'change',
          },
        ],
        out_place: [
          {
            required: true,
            type: 'string',
            message: '请设置前往地点！',
            trigger: 'change',
          },
        ],
        start_time: [
          {
            required: true,
            type: 'string',
            message: '请设置出发日期！',
            trigger: 'change',
          },
        ],
        out_reason: [
          {
            required: true,
            type: 'string',
            message: '请设置出差事由！',
            trigger: 'change',
          },
        ],
      })

      const { validate, validateInfos } = useForm(modelRef, rulesRef)

      const onSubmit = (e) => {
        //提交
        e.preventDefault()
        validate()
          .then(() => {
            const data = JSON.parse(JSON.stringify(modelRef))
            data.attachment_json = JSON.stringify(attachments_json.value)
            data.workflow_json = JSON.stringify(workflow_json.value)
            data.out_people_json = JSON.stringify(data.out_people_json)
            data.out_vehicle_json = JSON.stringify(data.out_vehicle_json)
            data.out_hotel_json = JSON.stringify(data.out_hotel_json)
            onSubmitForm(data)
          })
          .catch((err) => {
            console.log('error', err)
          })
      }

      const onSubmitForm = (data) => {
        if (validateInfoFun()) return false
        requests({
          url: API.project_trips.Store,
          data: data,
        })
          .then((res) => {
            notification['success']({
              message: '提示',
              description: '添加成功！',
            })
            goToPageMy()
          })
          .catch((err) => {
            console.log(err)
          })
      }
      const goToPageMy = () => {
        router.push(`/admin/businesstrip/my_list`)
      }
      const rformProps = {
        modelRef,
        rulesRef,
      }
      const onchangeTime = (dates, dateStrings) => {
        modelRef.start_time = dateStrings
      }
      const onChangeBack_time = (dates, dateStrings) => {
        modelRef.back_time = dateStrings
      }
      const uploadImgFun = (url) => {
        const data = [{ url: url, name: '' }]
        attachments_json.value = [...attachments_json.value, ...data]
      }
      const deleteAnnexFun = (idx) => {
        attachments_json.value.splice(idx, 1)
      }
      const goPage = () => {
        router.go(-1)
      }
      const getProject = (data) => {
        requests({
          url: API.project_trips.Show,
          data: data,
        })
          .then((res) => {
            const data = res.data
            data.out_hotel_json = JSON.parse(data.out_hotel_json)
            data.out_vehicle_json = JSON.parse(data.out_vehicle_json)
            data.out_people_json = JSON.parse(data.out_people_json)
            projectInfo.value = data
            console.log(res, 'skk')
          })
          .catch((err) => {
            console.log(err)
          })
      }
      onMounted(() => {
        getProject(route.query)
      })
      return {
        rformProps,
        model: modelRef,
        rules: rulesRef,
        validateInfos,
        onSubmit,
        onchangeTime,
        onChangeBack_time,
        uploadImgFun,
        deleteAnnexFun,
        attachments_json,
        workflow_json,
        goPage,
        projectInfo,
      }
    },
  }
</script>
